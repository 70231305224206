/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NUMBER_01_COLOR, NUMBER_02_COLOR, NUMBER_03_COLOR, QUESTION_MARK_COLOR } from "./colors";
import { DEMO_REPORT_URL, ONE_TIME_PRICE, SUBSCRIPTION_PRICE, TAX_YEAR, FREE_ASSESSMENT_PROPERTY_PRICE } from ".";
import { isPreOrderFlow } from "../configs";
import { Link } from "react-router-dom";
import { CodeHere } from "../components/Modals/AssessmentCodeModal";
import { ReportPlanType } from "../containers/SelectReportPage";

export const landingPage = {
  header: {
    title: "Property Taxes Seem High?",
    subtitle1: "Your property's appraised value may not be accurate.",
    subtitle2: "Find out today with a free assessment based on comparable sales and appraisals in your area.",
    button: "Get your Free Assessment",

    titlePreorder: "Protest Your Taxes Like a Pro",
    subtitle1Preorder: "Is your property's appraised value too high? Let us help.",
    subtitle2PreOrder:
      "Start with a free value verification based on comparable sales and appraisals in your area. Then, if you're eligible for savings learn how to get evidence, file your protest, and stop overpaying.",
    buttonPreOrder: "Start my protest",
    getTitle() {
      return isPreOrderFlow ? this.titlePreorder : this.title;
    },
    getSubtitle1() {
      return isPreOrderFlow ? this.subtitle1Preorder : this.subtitle1;
    },
    getSubtitle2() {
      return isPreOrderFlow ? this.subtitle2PreOrder : this.subtitle2;
    },
    getButtonText() {
      return isPreOrderFlow ? this.buttonPreOrder : this.button;
    },
  },

  navbar: {
    assessmentButtonText: "Free Assessment",
    assessmentPreOrderButtonText: "Free Estimate",
    getASsessmentButtonText() {
      if (isPreOrderFlow) {
        return this.assessmentPreOrderButtonText;
      }
      return this.assessmentButtonText;
    },
  },

  firstSection: {
    title: "Why you're probably paying too much.",
    paragraphs: [
      "Incomplete, incorrect, and outdated data mean that sometimes your value is little more than an educated guess.",
      "Do you know your home's tax value? How about the way your appraisal districts calculates your appraised tax value? If the answer is no, then your appraised value is probably too high.",
      "Texas has seen rapid growth and appraisal districts have been increasing values just as quickly. But these increases are not always specific to your home, and you may be paying for improvements you never made.",
    ],
  },

  testimonialsSection: {
    title: "See what your neighbors have to say",
  },

  secondSection: {
    title: "Filing Your Own Protest: You Can Do It, We'll Help",
    cards: [
      {
        title: isPreOrderFlow ? "Get your free estimate" : "Get your free appraisal verification",
        bgNumber: { text: "01", color: NUMBER_01_COLOR },
        buttonText: isPreOrderFlow ? "Get Your Estimate" : "Verify Your Appraisal",
        paragraphs: [
          "Simply enter your address and PPT will assess the accuracy of your property's value at no charge.",
          isPreOrderFlow
            ? "PPT will search 1000s of records to identify comparable properties and evaluate your home’s value. Using public and private sales and assessment data, the lowest defensible value is calculated for your home."
            : "PPT will find comps and evaluate your home's value according to two different government-approved appraisal methods.",
          isPreOrderFlow ? "" : "Your PPT assessment will be emailed to you in a few hours.",
        ],
        functionCallback: "verifyAppraisal",
      },
      {
        title: "Order your report",
        bgNumber: { text: "02", color: NUMBER_02_COLOR },
        buttonText: "See A Demo Report",
        functionCallback: "seeDemo",
        paragraphs: [
          "Was your county appraisal accurate? If not, PPT provides all the evidence you need to file a protest and reduce your tax bill.",
          `Order your Contestation Report for a $${SUBSCRIPTION_PRICE} fee and receive detailed comps and data arguing for your lower appraisal as well as step-by-step instructions to file your protest and stop paying unfair taxes.`,
        ],
      },
      {
        title: "File your protest (and pay less)",
        bgNumber: { text: "03", color: NUMBER_03_COLOR },
        paragraphs: [
          "E-file your protest online quickly and easily with the info provided in your PPT report.",
          "After review, local appraisal districts typically offer settlements. If you're unhappy with the settlement, you will submit your report to the Appraisal Review Board.",
          "If they reject your protest? You pay nothing.",
        ],
      },
      {
        title: "Have Questions?",
        bgNumber: { text: "?", color: QUESTION_MARK_COLOR },
        paragraphs: [
          "Don't worry, you may be filing yourself but you're not on your own.",
          "We provide step-by-step instructions for you along the way.  And if anything comes up, our customer care team is here to help at every stage. Contact us via phone or email for support.",
        ],
        buttonText: "Find Out More About Us",
        functionCallback: "goAbout",
      },
    ],
  },

  appraisalFairSection: {
    title: "Make Sure Your Tax Appraisal is Fair. No risk. No Committment.",
    button: "Check Your Value",
  },

  thirdSection: {
    title: "Why Protesting Matters",
    paragraphs: [
      "Never protested before? You're probably paying too much. In 2019, our assessments showed homes were overvalued by an average of 15%.",
      "Imagine: Bob and Bill are neighbors in comparable $300,000 homes. Each year, their local appraisal district increases values by 10%.",
      "Bill protests his appraised value, and each year he wins a 5% reduction. Bob never protests. After 5 years, there's a $100,000 difference in their appraised tax values, despite their identical homes.",
      "While this hypothetical is an oversimplification, local appraisal districts choose their own methodology and data. No appraisal district has the resources to truly calculate each home's unique value.",
      "Your appraisal may not be calculated the same way as your neighbor's.  If you've never protested, you're probably paying too much.",
    ],
  },

  finalSection: {
    title: "Fair, Accurate Property Assessments For All",
    imageName: "Protesting Property Taxes",
  },
};

export const FAQPageQuestions = {
  title: "Frequently Asked Questions.",
  rows: [
    {
      title: "What is a Property Tax Protest?",
      content: () => (
        <>
          Each year, your local appraisal district provides an Appraised Taxable Value for your property. If you believe
          that value is incorrect, Texas law gives you the right to contest, or protest, that value. When you file, you
          submit information about your property and the value you believe is accurate. You must provide evidence
          supporting your claim such as comparable properties' assessments or market prices.
          <br/><br/>
          For information on
          protesting in Travis County visit the
          <a href="https://www.traviscad.org/protests/"> TCAD website</a>. For Bexar County, visit the{" "}
          <a href="http://www.bcad.org/index.php/Tax_Information">BCAD website</a>.
          For Collin County, visit the{" "}
          <a href="https://www.collincad.org//">Collin Central Appraisal District website</a> and search for your property.
          For Dallas County, visit the{" "}
          <a href="https://www.dallascad.org/">Dallas CAD website</a>, search for your property, and select the uFile Online Protest Program.
          For Denton County, visit the{" "}
          <a href="https://eprotest.dentoncad.com/">Denton CAD eFile portal site</a>.
          For Fort Bend County, visit the{" "}
          <a href="https://webappeals.fbcad.org/">FBCAD web appeals site</a>.
          For Harris County, visit the{" "}
          <a href="https://hcad.org/hcad-online-services/ifile-protest/">HCAD website</a>. For Hays County, visit the{" "}
          <a href="https://www.hayscad.com/protest-info/">Hays CAD website</a>. For Montgomery County, visit the{" "}
          <a href="https://mcad-tx.org/OnlineAppeals/">MCAD online appeals site</a>.
          For Tarrant County, create an account on the{" "} <a href="https://www.tad.org/">Tarrant Appraisal District website</a>.
          For Williamson County, visit the{" "}
          <a href="https://www.wcad.org/online-protest-filing/">WCAD website</a>.
        </>
      ),
      id: "what_is_a_property_tax_protest",
    },

    {
      title: "How do I protest my Appraised Value?",
      content: () => (
        <>
          To protest your property, simply e-file on your appraisal district's website. The process is quick and simple
          if you have all the necessary evidence to support your claim. If you do not want to research comparable
          properties yourself, ProtestingPropertyTaxes.com's Contestation Report assembles all the evidence you need to
          file. Simply attach your PPT Contestation Report to your claim and submit.
        </>
      ),
      id: "how_do_i_protest_appraised",
    },
    {
      title: "What is a Contestation Report?",
      content: () => (
        <>
          Determining the taxable value of your property and gathering evidence can be complicated.
          ProtestingPropertyTaxes.com has simplified the process with our Contestation Report. The Contestation Report
          is an evidence packet created specifically for your property to support an Appraised Value protest. The report
          calculates the correct Appraised Taxable Value of your property and supports that value with comparable
          properties, value adjustments, and explanation of methods and data. The report is all you need to file a
          strong protest claim. View a demo report
          <a href={DEMO_REPORT_URL}> here.</a>
        </>
      ),
      id: "what_is_contestation_report",
    },
    {
      title: "Will ProtestingPropertyTaxes.com file my protest?",
      content: () => (
        <>
          No, we provide all of the information so you can file and complete your protest yourself. We are not a
          full-service property tax agent. However, we guide you through the process from filing to completing the
          formal hearing with step-by-step instructions. If you need additional help, our customer representatives are
          available at <a href="mailto:help@protestingpropertytaxes.com">help@protestingpropertytaxes.com</a> .
        </>
      ),
      id: "who_files_my_protest",
    },
    {
      title: "What happens after I submit my protest?",
      content: () => (
        <>
          Time frames depend on the volume of protests, but you should hear back from your local appraisal district in
          about 2 weeks. You do not need to speak with an appraiser or complete an informal hearing. The district will
          email you with a settlement offer or schedule a formal hearing. Settlement amounts can vary widely and are not
          necessarily an indication of the strength of your protest case. You may choose to reject your settlement offer
          and proceed to a formal hearing. The formal hearing is decided by the independent Appraisal Review Board and
          can be completed in person, via phone, or in absentia via a Notarized Affidavit.
        </>
      ),
      id: "what_happens_after_submit_protest",
    },
    {
      title: "I received a settlement offer, should I accept or reject it?",
      content: () => (
        <>
          It is entirely up to you whether or not to accept a settlement offer. Settlements come as part of the informal
          stage of protesting, and the appraisal district is not obligated to review your evidence before it makes a
          settlement offer. That means your settlement offer is not necessarily a reflection of the strength of your
          case. If you reject the district's offer, you will proceed to a formal hearing. In the formal hearing, a
          three-member independent body is required to consider both your evidence and the district's, and the burden of
          proof is on the district to show that your evidence does not follow Texas law. The formal hearing can be
          completed in person, via phone, or in absentia via a Notarized Affidavit. If the formal hearing results in
          your protest being rejected, you cannot go back and accept the previous settlement offer.
        </>
      ),
      id: "settlement_accept_or_reject",
    },
    {
      title: "I went to a formal hearing, but my Appraised Value wasn't reduced enough. What do I do?",
      content: () => (
        <>
          If you are dissatisfied with the results of your hearing, please contact us at{" "}
          <a href="mailto:help@protestingpropertytaxes.com">help@protestingpropertytaxes.com</a>. Each case is unique,
          and if your protest did not cover the cost of your report, we will do our best to make it right.
        </>
      ),
      id: "formal_hearing_no_reduction",
    },
    {
      title: "I need help filing my online protest. What do I do?",
      content: () => (
        <>
          We've put together a{" "}
          <a href="https://texas-taxes-assets.s3.us-east-2.amazonaws.com/Property+Tax+Protest+Instructions.pdf">
            step-by-step filing guide
          </a>
          , complete with screenshots to instruct you on the E-File process. You can also find instructional videos for
          TCAD's E-File system at the bottom of <a href="https://www.traviscad.org/protests/">this page</a> and at the
          bottom of <a href="http://www.bcad.org/index.php/Portal_Banner">this page</a> for BCAD.
        </>
      ),
      id: "help_filing_online_travis",
    },
    {
      title: "How much can I expect to save in my protest?",
      content: () => (
        <>
          Our report finds the strongest evidence for the lowest, defensible taxable value for your property. This gives
          you the best chance at saving. The result of a successful protest is likely to be somewhere in between the
          appraised value in your report and the appraisal district's original appraised value. When we communicate
          potential savings to you we show our estimate of the best case scenario from a protest using our Contestation
          Report as evidence.
        </>
      ),
      id: "how_much_expect_to_save",
    },
    {
      title: "What is the ProtestingPropertyTaxes.com Money Back Guarantee?",
      content: () => (
        <>
          Our goal is to make protesting easier and help save our customers money. So if you purchase a report and do
          not receive a reduction in Appraised Value (also known as your property's Taxable Value or Market Value, depending on your CAD) from your appraisal district (in the form of a Settlement Offer) or
          from the Appraisal Review Board (after completing a formal hearing), we will refund the cost of your report.
          Simply forward the ARB's decision notice to {" "}
          <a href="mailto:help@protestingpropertytaxes.com">help@protestingpropertytaxes.com</a>  and your refund will
          be processed. Please note, increasing tax rates and homestead or other exemption changes mean a decreased
          Appraised Taxable Value does not always result in lower taxes.
        </>
      ),
      id: "money_back_guarantee",
    },
    {
      title: "How do I cancel my yearly report service?",
      content: () => (
        <>
          Annual report customers are the first to receive property tax contestation reports. We work to process appraisal district data as soon as it is released each year and use that data to create reports for our yearly report buyers first, giving them the most time to file an informed protest and the quickest property tax resolution with their CAD.  
          However, if you no longer want to protest yearly, simply send an email to <a href="mailto:help@protestingpropertytaxes.com">help@protestingpropertytaxes.com</a> letting us know you want to cancel and we will no longer automatically create a contestation report for you.
        </>
      ),
      id: "cancel_yearly",
    },
    {
      title: "I signed up for the ProtestingPropertyTaxes.com yearly report service. If I decide not to protest after I receive my report, can I get a refund?",
      content: () => (
        <>
          You are only charged after we have identified sufficient evidence to support a meaningful protest and your report has been reviewed and approved by a licensed property tax consultant. Once your report has been emailed to you, we cannot refund the report unless it qualifies for our money-back guarantee. To qualify for the money-back guarantee, you must complete the protest process, through the Appraisal Review Board formal hearing, without receiving a reduction in Appraised Market Value. Choosing not to complete the protest process does not qualify for a refund. If you change your mind after placing your order but before receiving the report, please contact <a href="mailto:help@protestingpropertytaxes.com">help@protestingpropertytaxes.com</a>{" "} as early as possible to cancel your order before the report is created. If you are signed up for yearly service, you must notify us by April 15th to cancel your order for the year.
        </>
      ),
      id: "yearly_report_service_protest_cancel",
    },

    {
      title: "My Appraised Value did not change this year. Can I still protest?",
      content: () => (
        <>
          Yes, every property is eligible to protest every year. Whether or not your property was reappraised in 2020 or
          not, you can protest. Homeowners who accepted settlements in past years are still eligible to protest. While
          Appraised Values may not change every year, market prices do and homeowners are entitled to have their taxable
          value recalculated.
        </>
      ),
      id: "my_appraised_value_did_not_change",
    },
    {
      title: "How do I know if my Appraised Value is too high?",
      content: () => (
        <>
          Your appraisal district offers a property search feature where you can look up similar properties. You must
          find properties with the same bedrooms, bathrooms, square footage, lot size, and year built as yours or make
          the appropriate value adjustments. Or you can use ProtestingPropertyTaxes.com's Appraisal Verification tool.
          We will do the search and calculations for you and email you the results.
        </>
      ),
      id: "how_do_i_know",
    },
    {
      title: "How do I protest my Appraised Value?",
      content: () => (
        <>
          To protest your property appraisal, you simply E-File on your appraisal district's website. The process is
          quick and simple if you have all the necessary evidence to support your claim. ProtestingPropertyTaxes.com's
          Contestation Report includes all the evidence you need to protest and provides step-by-step instructions to
          guide you through the entire process. If you go to a formal hearing, your Contestation Report can be used to
          file a Notarized Affidavit so you do not need to appear in person.
        </>
      ),
      id: "how_do_i_protest",
    },
    {
      title: "What if my protest is rejected by the Appraisal Review Board?",
      content: () => (
        <>
          With ProtestingPropertyTaxes.com's Money Back Guarantee, you pay nothing for your report if your protest is
          rejected. Simply email <a href="mailto:help@protestingpropertytaxes.com">help@ProtestingPropertyTaxes.com</a>{" "}
          the notification from the Appraisal Review Board and we will refund the cost of your Contestation Report.
        </>
      ),
      id: "what_if_rejected",
    },
    {
      title: "If I protest, could my Appraised Value go up?",
      content: () => (
        <>
          No, the Appraisal Review Board cannot increase your Appraised Value this tax year beyond the district's
          original appraisal. If your protest is rejected, your Appraised Value will simply stay the same. Next year,
          however, the appraisal district can make whatever adjustments they choose.
        </>
      ),
      id: "protest_could_appraised_value_increase",
    },
    {
      title: "Could a lower Appraised Value reduce my home's market price?",
      content: () => (
        <>
          No, the appraisal district valuation of your home is calculated differently than a real estate agent's sales
          price. The Appraised Taxable Value does not dictate the market price should you decide to sell. A lower
          Appraised Taxable Value simply means lower property taxes.
        </>
      ),
      id: "could_lower_be_reduced",
    },
    {
      title: "How does ProtestingPropertyTaxes.com calculate my home's value?",
      content: () => (
        <>
          ProtestingPropertyTaxes.com uses machine learning to search public and private property information to find
          the best comparable properties for your home. We use two different methodologies, Market Value and Equal and
          Uniform, to ensure the fairest appraisal. Before you receive your appraisal it is reviewed and approved by a
          Licensed Property Tax Consultant.
        </>
      ),
      id: "how_does_calculate",
    },
  ],
};

export const AboutUsText = {
  paragraphs: [
    () => (
      <p>
        ProtestingPropertyTaxes.com is an extension of{" "}
        <a href="https://www.austinrealestatepartners.com/">Austin Real Estate Partners</a>, a full service real estate
        firm that was founded by Bob and Melinda McKenna and has been serving Austin and Travis County homeowners for
        almost 20 years.
      </p>
    ),
    () => (
      <p>
        Austin Real Estate Partners is a trusted resource for clients long after keys are exchanged and moving trucks
        are gone. Those long term relationships are what led Bob and Melinda to realize property tax assessments often
        do not match the appraisal or sale value of a home. Time and time again, Bob and Melinda saw clients' tax
        assessments coming in with values <a>far above the actual sale price.</a> They knew they needed to find an
        innovative solution and help their clients contest these assessments and pay lower taxes.
      </p>
    ),
    () => (
      <p>
        However, the options available were either too much work or too much money. Austin Real Estate Partners could
        offer instruction on how to contest property taxes but that meant busy clients still needed to do the research,
        find comps, and dispute the appraisal with their district. Alternatively, they could refer clients to costly
        Property Tax Consultants who would do it all but would also end up <a>taking the bulk of the savings.</a>
      </p>
    ),
    () => (
      <p>
        Then Bob and Melinda found the technology and team behind ProtestingPropertyTaxes.com. Using the power of
        machine learning, clients get the{" "}
        <a>detailed research of a high priced property tax appeals agent at the cost of a do-it-yourself solution.</a>{" "}
        ProtestingPropertyTaxes.com's personalized report makes it easy for clients to get a fair tax rate without high
        commissions or fees.
      </p>
    ),
  ],
};

export const pricingText = {
  firstSection: {
    title: "FAIR TAXES, FAIR PRICING",
    subtitle: `We work everyday to make property tax protests and fair property tax valuations available to all Texas homeowners. Our mission means a commitment to fair, transparent pricing. Yearly customers pay $${SUBSCRIPTION_PRICE} for our services each year, nothing more, no matter what.`,
    paragraphs: [
      {
        title: "Simple, Up-front Fee",
        icon: () => <div className="icon_title">${SUBSCRIPTION_PRICE}</div>,
        text: `Our reports cost only $${SUBSCRIPTION_PRICE} for yearly customers and $${ONE_TIME_PRICE} for one-time orders.`,
        background: "#f2f2f2",
      },
      {
        title: "Keep Your Savings, No Commissions",
        icon: () => <img src={require("../assets/img/pricing_icon_1.png")} alt="" />,
        text: "Whether you save $500 or $5000, it's yours to keep. We'll give you everything that you need, but you file your own protest. Since we do not file or attend hearings for you, we never take any commission.",
        background: "#ccdbd9",
      },
      {
        title: "Money Back Guarantee",
        icon: () => <img src={require("../assets/img/pricing_icon_2.png")} alt="" />,
        text: "Protest doesn't work? Get your money back. If you do not receive a reduction from your appraisal district or from the Appraisal Review Board after completing a formal hearing, you get your money back. Simply contact us with the ARB's decision notice and your refund will be processed.",
        background: "#bfc8d7",
      },
      {
        title: "Giving Back",
        icon: () => <img src={require("../assets/img/pricing_icon_3.png")} alt="" />,
        text: `We give free reports to the Texas homeowners who are least likely to protest, because fair taxes shouldn't just be for the wealthy. Our full services are provided at no cost to homeowners of properties appraised below $${FREE_ASSESSMENT_PROPERTY_PRICE.toLocaleString()}.`,
        background: "#b6b6b6",
      },
    ],
  },
  secondSection: {
    title: "PROTESTINGPROPERTYTAXES.COM VS. FULL SERVICE AGENTS",
    paragraphs: [
      "Full service agents take large portions of your savings as commissions, which isn't practical for most homeowners. After commission and fees there simply may not be enough savings to justify your protest. With our report and step-by-step instructions, you're able to easily file your protest online for yourself. Since you handle your own filing and hearings, we never charge commission. You file your protest, so you get to keep your savings.",
      <img src={require("../assets/img/pricing_table.png")} alt="" />,
      `We work hard so your report supports the lowest defensible Taxable Value for your home. However, the best way to keep your assessment low is to protest every year. This is why yearly customers pay only $${SUBSCRIPTION_PRICE} and receive their evidence report automatically when the counties release assessment data. Yearly customers can cancel at any time.`,
      "The whole point of our service is to reduce your Appraised Value. So if you purchase a report and do not receive a reduction from your appraisal district (in the form of a Settlement Offer) or from the Appraisal Review Board (after completing a formal hearing), you get your money back. Simply forward us the ARB's decision notice to and your refund will be processed.",
    ],
  },
};

export const preOrderFlow = {
  reportForm: {
    title: "Order Your Contestation Report",
    instructions: [
      "Expert-researched evidence and comps",
      "Step-by-step filing instructions and customer support",
      `You won't be charged until a state-licensed property tax appraiser completes your ${TAX_YEAR} contestation report.`,
      <>
        ${SUBSCRIPTION_PRICE} flat fee each year, no commission. No risk,{" "}
        <Link to="/faq#money_back_guarantee" target="_blank">
          Money Back Guarantee
        </Link>
        .
      </>,
      <>
        Your credit card information is securely stored by our payments partner,{" "}
        <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>
      </>,
      (props) => <CodeHere onClick={props.onToggleCodeModal}>Have a pre-order or coupon code?</CodeHere>,
    ],
    buttonText: "Submit",
  },
  successPayment: {
    title: `Congratulations! You've taken the first step towards lower, fairer taxes.`,
    paragraphs: [
      <p>
        {TAX_YEAR} property tax assessments will be released by the county appraisal districts beginning in April. We
        need this information to give you the most accurate and defensible evidence for your protest.{" "}
      </p>,
      <p>
        As soon as your report is ready, it will be emailed to the address provided with your step-by-step filing guide.
        By purchasing today, you've reserved your spot and will be among the first customers to receive your report.
      </p>,
      <p>
        <b>Don't worry, you will not be charged until your report is ready.</b>
      </p>,
      <p>
        Keep an eye out for notices from your local appraisal district and emails from ProtestingPropertyTaxes.com.
      </p>,
      <p>
        Have questions? Contact us today at{" "}
        <a href="mailto:help@protestingpropertytaxes.com">info@protestingpropertytaxes.com.</a>
      </p>,
    ],
  },
};

export const testimonials = [
  {
    name: "Sandra T.",
    county: "Bexar County",
    content: `It worked. My protest was successful. The information that I obtained from your company did the trick. I'm sorry that I was skeptical at first, but in these times of people taking advantage of others, I was just being cautious. Once again, thanks very much for your service and patience with me.`,
  },
  {
    name: "Susan A.",
    county: "Travis County",
    content: `My settlement offer was exactly the value you estimated which was substantially below the original value the county assigned - I’m very happy with the service.`,
  },
  {
    name: "John and Dolly",
    county: "Bexar County",
    content: `Just wanted to say THANK YOU! Your report, our pictures of issues with the property, their incorrect information on their appraisal... actually got it lowered below your value.
    Greatly appreciated!!!`,
  },
  {
    name: "Celia D.",
    county: "Bexar County",
    content: `This is great service during these difficult times. As you can see I don't ever protest my valuations which has been a huge mistake on my behalf. I wish I would've done it sooner.`,
  },
];

export const reportPlanSelection = {
  title: "Choose your report",
  nittyGritty: (
    <>
      For more on our money back guarantee visit our{" "}
      <Link to="/faq#money_back_guarantee" target="_blank">
        FAQ
      </Link>
    </>
  ),
  options: [
    {
      name: ReportPlanType.ONE_TIME,
      price: ONE_TIME_PRICE,
      features: ["Expert evidence", "Backed by a licensed property tax consultant", "Step-by-step filing guide"],
      style: {
        background: "#b6b6b6",
      },
    },
    {
      name: ReportPlanType.YEARLY,
      price: SUBSCRIPTION_PRICE,
      features: ["Never miss a deadline, evidence sent annually", "Money back guarantee", "Cancel anytime"],
      style: {
        background: "#ccdbd9",
      },
    },
  ],
};
