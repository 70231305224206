import React from "react";
import {
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
} from "reactstrap";
import Autosuggest from "react-autosuggest";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";

// app
import { formatAddress } from "../../utils";
import "./autosuggest-theme.css";

const CURRENT_STEP = 0;

class LookupAddressForm extends React.Component {
  state = {
    address: "",
    selectedSuggestion: null,
    suggestionStarted: true,
  };

  componentDidUpdate() {
    const { addressSuggestions, selectAddress, onSubmit } = this.props;
    if (addressSuggestions.length === 1) {
      selectAddress(addressSuggestions[0]);
      onSubmit(CURRENT_STEP + 1);
    }
  }

  handleCallSuggestions = debounce((str) => {
    const { getAddressSuggestions } = this.props;
    getAddressSuggestions(str);
    this.setState({ suggestionStarted: false });
  }, 400);

  handleChange = (e, { newValue }) => {
    const { selectedSuggestion } = this.props;
    if (newValue !== selectedSuggestion) {
      this.props.selectAddress({});
    }
    const address = newValue;
    this.setState({
      address,
    });
  };

  onSuggestionsClearRequested = () => {
    const { clearSuggestions } = this.props;
    clearSuggestions();
  };

  onSuggestionFetchRequested = ({ value }) => {
    const { address } = this.state;

    if (value === address) {
      return;
    }

    if (value.trim() !== "") {
      this.setState({ suggestionStarted: true });
      return this.handleCallSuggestions(value);
    }

    this.props.clearSuggestions();
  };

  getSuggestionValue = (suggestion) => {
    const { selectAddress, onSubmit } = this.props;

    this.setState(() => {
      selectAddress(suggestion);
      onSubmit(CURRENT_STEP + 1);
      return {
        selectedSuggestion: formatAddress(suggestion),
      };
    });

    return formatAddress(suggestion);
  };

  renderSuggestion = (suggestion) => <span>{formatAddress(suggestion)}</span>;

  renderAddressNotFound = () => {
    const { address, suggestionStarted } = this.state;
    const { addressSuggestions, isLoading } = this.props;

    if (
      address.trim() !== "" &&
      addressSuggestions.length < 1 &&
      !isLoading &&
      !suggestionStarted
    ) {
      return <small style={{ color: "red" }}>Address Not Found</small>;
    }
  };

  render() {
    const { address } = this.state;
    const { addressSuggestions, isLoading } = this.props;

    const inputProps = {
      onChange: this.handleChange,
      value: address,
      placeholder: "Address",
      type: "address",
    };

    return (
      <>
        <h4 className="title">
          We need to know your address to get started, enter it below.
        </h4>
        <Form className="register-form" onSubmit={(e) => e.preventDefault()}>
          <InputGroup className="form-group-no-border">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                {isLoading ? (
                  <Spinner
                    color="info"
                    style={{ height: "17px", width: "17px" }}
                  />
                ) : (
                  <i className="fa fa-home" />
                )}
              </InputGroupText>
            </InputGroupAddon>
            <Autosuggest
              suggestions={addressSuggestions}
              renderSuggestion={this.renderSuggestion}
              onSuggestionsFetchRequested={this.onSuggestionFetchRequested}
              getSuggestionValue={this.getSuggestionValue}
              inputProps={inputProps}
              renderInputComponent={(inputProps) => <Input {...inputProps} />}
              alwaysRenderSuggestions
            />
          </InputGroup>
          {this.renderAddressNotFound()}
          <br />
          <small>
            We only support single-family homes and condominiums in Bexar, Collin, Dallas, Denton, Fort Bend, Harris, Hays, Montgomery, Tarrant, Travis, and Williamson counties.
            If you're having trouble finding your address, please email{" "}
            <a href="mailto:help@protestingpropertytaxes.com">
              help@protestingpropertytaxes.com
            </a>
            .
          </small>
        </Form>
      </>
    );
  }
}

LookupAddressForm.propTypes = {
  addressSuggestions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  getAddressSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  selectAddress: PropTypes.func.isRequired,
};

export default LookupAddressForm;
